<template>
    <div class="gray-bg" v-htmltit data-title="拍单">
        <div class="photo-order my-main">
            <go-cart-btn></go-cart-btn>
            <go-photo-btn></go-photo-btn>
            <div class="photo-order-tab">
                <van-tabs v-model="tabActive" swipeable sticky color="#7C7C7C" title-active-color="#2F2F2F">
                <van-tab v-for="item in tabList" 
                    :key="item.name" 
                    :name="item.name" 
                    :title="item.title + ('('+item.count+')')" >
                    <div class="photo-order-list">
                        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                            <van-list
                                class="photo-order-list"
                                v-model="loading"
                                :immediate-check="false"
                                :finished="finished"
                                finished-text="已经到底了"
                                @load="onLoad">
                                <div class="photo-order-item" @click="goDetail(item)" v-for="item in photoList" :key="item.id">
                                    <div class="photo-order-num">记录单号：{{item.record_order_no}}</div>
                                    <div class="photo-list" v-if="item.img_list.length > 0">
                                        <div class="photo-item-box">
                                            <div class="photo-item">
                                                <template  v-for="(photoItem,photoIndex) in item.img_list">
                                                    <van-image fit="contain"
                                                        class="img-box"
                                                        :key="photoIndex"
                                                        v-if="photoIndex < 4"
                                                        :src="getImgSizeJpg(photoItem,3.6,3.6)">
                                                        <template v-slot:error>
                                                            <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                                        </template>
                                                        <template v-slot:loading>
                                                            <img style="width:100%;height:3.6rem" :src="defaultImg" />
                                                        </template>
                                                    </van-image>
                                                </template>
                                            </div>
                                            <div class="go-detail">
                                                <span>查看详情</span>
                                                <van-icon name="arrow" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="photo-order-time">
                                        <div class="photo-time">{{item.record_order_operation}}</div>
                                        <div class="photo-opt">
                                            <div v-if="tabActive == 1" @click.stop="cancelOrder(item.id)" class="photo-opt-btn cancel">取消</div>
                                            <!-- <div v-if="tabActive == 2" @click.stop="handleGoPay(item.id)" class="photo-opt-btn pay">结算</div> -->
                                            <!-- <div v-if="tabActive == 3" @click.stop="handleGoPayAgain(item)" class="photo-opt-btn pay">支付</div> -->
                                        </div>
                                    </div>
                                </div>
                            </van-list>
                        </van-pull-refresh>
                    </div>
                </van-tab>
            </van-tabs>
            </div>
        </div>
        <my-footer></my-footer>
    </div>
</template>
<script>
import './index.less'
import myFooter from "@/components/Footer"
import goCartBtn from "@/view/Public/goCartBtn"
import goPhotoBtn from "@/view/Public/goPhotoBtn"
import defaultImg from "@/assets/image/default2.png"
import bshop_global from "@/libs/global"
import { recordOrderList, recordOrderTabs, recordOrderCancel ,orderPayAgain } from "@/apis/api"
import { compare ,wxConfig} from '@/libs/utils'
const status = {
    '1': '待处理',
    '2': '已处理',
    '3': '待支付',
    '4': '已完成',
    '5': '已失效'
}

export default {
    name:"photo-order",
    components:{ myFooter , goCartBtn ,goPhotoBtn},
    data(){
        return{
            isFirstEnter: true,
            defaultImg:defaultImg,
            tabList:[],
            tabActive:0,
            page:1,
            pageSize:10,
            isLoading:false,//下拉刷新loading
            loading: false,//上拉加载列表的数据
            finished: false,//上拉加载列表的数据
            photoList:[]
        }
    },
    watch:{
        tabActive:{
            handler (v) {
                this.page = 1
                this.photoList = [];
                this.finished = false;
                this.loading = true;
                this.onLoad();
            }
        }
    },
    mounted(){
        this.isFirstEnter = true
        this.getCount()
    },
    activated(){
        if(!this.isFirstEnter || this.$route.meta.isfresh){
            this.getCount()
            let keepAliveArr = ['photo-order-detail','add-photo-order','photo-order-confirm','cart']
            const beforeRouterName = this.beforeRouter.name;
            if ((beforeRouterName &&  !keepAliveArr.includes(beforeRouterName)) ||(this.$route.meta.isfresh)) {
                // 手动刷新页面
                this.$route.meta.isfresh = false
                this.photoList = []
                this.onRefresh()
            }
        }
        this.isFirstEnter = false
    },
    methods:{
        onRefresh() {
            this.getCount()
            this.page = 1
            this.finished = false;
            this.loading = true;
            this.onLoad()
        },
        onLoad() {
            let data = {
                page_no:this.page,
                page_size:10,
                state:this.tabActive
            }
            recordOrderList(data).then(res=>{
                if(res.code == 0){
                    if (this.isLoading) {
                        this.photoList = [];
                        this.isLoading = false;
                    }
                    this.page++;
                    let newList = res.res.record_order_list;
                    if(newList.length < this.pageSize){
                        this.finished = true; 
                    }
                    this.photoList = this.photoList.concat(newList);
                    // 加载状态结束
                    this.loading = false;
                    // 数据全部加载完成
                    if(this.photoList.length >= res.res.count){
                        this.finished = true;
                    }
                }else{
                     this.$toast(res.msg)
                }
            })
        },
        //获取tabs数量列表
        getCount(){
            recordOrderTabs().then(res=>{
                if(res.code == 0){
                    let data = res.res.status_list;
                    data.map(item => {
                        item.title = status[item.name]
                    })
                    data.sort(compare('name'))
                    let newData = data.filter(function (obj) { if (obj.name == "1" || obj.name == "2" || obj.name == "5") { return obj; } })
                    this.tabList = newData
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        //取消订单
        cancelOrder(id){
            this.$dialog.confirm({
                width:'88%',
                title: '提示',
                message: '确认取消吗？'
            }).then(() => {
                let params = {
                    record_id_list:[id]
                }
                recordOrderCancel(params).then(res=>{
                    if(res.code == 0){
                        this.$toast("成功取消")
                        this.page = 1
                        this.photoList = [];
                        this.finished = false;
                        this.loading = true;
                        this.onLoad();
                        this.getCount();
                    }else{
                        this.$toast(res.msg)
                    }
                })
            }).catch(()=>{})
        },
        //点击结算
        handleGoPay(id){
            this.$router.push({
                name:"photo-order-confirm",
                query:{ "id":id}
            })
        },
        //点击支付
        handleGoPayAgain(item){
            let toast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let params = {
                order_id:item.order_id
            }
            orderPayAgain(params).then(res=>{
                if(res.code == 0){
                    if(item.pay_type == 1){
                        this.$router.replace({ name:"payment-success" })
                    }else if(item.pay_type == 2){
                        wxConfig(res.res.wxJsApiParam);
                        //将返回的信息放在缓存中 以便重新支付时获取
                        localStorage.setItem("againRes",JSON.stringify(res.res.wxJsApiParam))
                    }else if(item.pay_type == 3){
                    }else if(item.pay_type == 4){
                        window.location.href =  bshop_global.bShopUrl + "/#/payinfo?code=" + res.res.orderId+"&customerId=" + localStorage.getItem('customerId') +"&sysOfficeId="+ localStorage.getItem('sysOfficeId');
                    } else if(item.pay_type == 5){
                        this.$router.replace({ name:"payment-success"})
                    }
                }else{
                    this.$toast(res.msg)
                }
                toast.clear();
            })
        },
        goDetail(item){
            this.$router.push({
                name:"photo-order-detail",
                query:{ "id":item.id }
            })
        }
    }
}
</script>